import {BehaviorSubject, Observable} from 'rxjs';

import {SocialUserDataCredentials} from '../models/user-verification';

export interface LoginProvider {

  initialized: boolean;

  state$: Observable<boolean>;

  initialize(): Promise<void>;

  signIn(signInOptions?: any): Promise<SocialUserDataCredentials>;

  signOut(revoke?: boolean): Promise<void>;
}

/**
 * Basic login provider for social network
 */
export abstract class BaseLoginProvider implements LoginProvider {

  /**
   * Subject for tracking initialization state of selected provider (SDK)
   *
   * @protected
   */
  protected readonly initState$ = new BehaviorSubject(false);

  readonly state$ = this.initState$.asObservable();

  get initialized(): boolean {
    return this.initState$.value;
  }

  /**
   * Method which initialize social network SDK.
   */
  abstract initialize(): Promise<void>;

  abstract signIn(signInOptions?: any): Promise<SocialUserDataCredentials>;

  abstract signOut(): Promise<void>;

  protected loadScript(
    id: string,
    src: string,
    onload: () => void,
    parentElement?: HTMLElement,
  ): void {
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      const signInJS = document.createElement('script');
      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;
      if (!parentElement) {
        parentElement = document.head;
      }
      parentElement.appendChild(signInJS);
    }
  }
}
