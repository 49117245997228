import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {SocialUserDto} from '@modules/social-auth/models/user-verification';
import {take, takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';

import {SocialAuthProvider} from '../../models';
import {SocialAuthService} from '../../services';

/**
 * Directive for button with providing social network
 */
@Directive({
  selector: '[appSocialAuth]',
})
export class SocialAuthDirective extends DestroySubscription implements OnInit {

  /**
   * Selected social network provider
   */
  @Input() appSocialAuth: SocialAuthProvider | undefined;
  /**
   * Disable button before initialization of social network script (SDK).
   */
  @Input() disableBeforeInit = true;
  /**
   * When user logged in - return whole info about user by sending request to API.
   * If value is false - directive will emit only selected provider and accessToken from SDK
   * (for connecting social networks inside user profile).
   */
  @Input() getUserByToken = true;

  @Output() updateSocialUser = new EventEmitter<SocialUserDto>();
  @Output() socialAuthError = new EventEmitter<any>();

  constructor(
    private readonly el: ElementRef<HTMLButtonElement>,
    private readonly socialAuthService: SocialAuthService,
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.disableBeforeInit) {
      return;
    }
    this.setDisableState(true);
    this.toggleDisableState();
  }

  private toggleDisableState(): void {
    if (!this.appSocialAuth) {
      return;
    }
    this.socialAuthService.getInitState(this.appSocialAuth).pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(state => this.setDisableState(!state));
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    const provider = this.appSocialAuth;
    if (!provider) {
      throw new Error('Social provider is not defined');
    }
    this.signIn(provider);
  }

  private signIn(provider: SocialAuthProvider): void {
    this.socialAuthService.signIn(provider, this.getUserByToken).pipe(
      take(1),
      takeUntil(this.destroyStream$),
    ).subscribe({
      next: (data) => this.updateSocialUser.emit(data),
      error: (error) => this.socialAuthError.emit(error),
    });
  }

  private setDisableState(disabled: boolean): void {
    const element = this.el.nativeElement;
    const renderer = this.renderer;
    const attributeName = 'disabled';
    disabled ? renderer.setAttribute(element, attributeName, 'false') : renderer.removeAttribute(element, attributeName);
  }
}
