import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable, of, throwError} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';

import {
  SocialAuthProvider,
  SocialAuthProviderConfig,
  SocialModuleAuthConfig,
  SocialUserDataCredentials,
  SocialUserDto,
  UserVerificationDto,
} from '../../models';
import {LoginProvider} from '../../models/login-provider';
import {SocialUserService} from '../social-user/social-user.service';

@Injectable()
export class SocialAuthService {

  private readonly providers = new Map<SocialAuthProvider, LoginProvider>();
  private readonly state$ = new BehaviorSubject<SocialUserDto | null>(null);
  readonly authState$ = this.state$.asObservable();

  constructor(
  @Inject(SocialModuleAuthConfig) config: SocialAuthProviderConfig[],
    private readonly socialUserService: SocialUserService,
  ) {
    config.forEach((item) => this.providers.set(item.type, item.provider));
    this.initializeProviders();
  }

  private initializeProviders(): void {
    const providers = Array.from(this.providers.values()).filter(provider => !provider.initialized).map(provider => provider.initialize());
    Promise.all(providers)
      .then()
      .catch((error) => console.error(error));
  }

  signIn(socialProvider: SocialAuthProvider, getUserByToken = false): Observable<SocialUserDto> {
    const provider = this.providers.get(socialProvider);
    if (!provider?.initialized) {
      return throwError(() => 'Provider is not initialized');
    }
    return from(provider.signIn()).pipe(
      switchMap((credentials: SocialUserDataCredentials) => {
        const baseData = {provider: socialProvider, accessToken: credentials.accessToken};
        if (!getUserByToken) {
          return of(baseData);
        }
        return this.socialUserService.socialAuth<UserVerificationDto, Omit<SocialUserDto, 'provider'>>({
          ...credentials,
          provider: socialProvider,
        }).pipe(
          map(data => ({...data, ...baseData})),
        );
      }),
      tap(data => this.state$.next(data)),
    );

  }

  signOut(): Observable<void> {
    return this.authState$.pipe(
      filter(state => !!state),
      switchMap((state: any) => {
        const provider = this.providers.get(state.provider);
        if (!provider) {
          return throwError(() => 'Provider not found');
        }
        return from(provider.signOut());
      }),
      tap(() => {
        this.state$.next(null);
      }),
    );
  }

  getInitState(socialProvider: SocialAuthProvider): Observable<boolean> {
    const provider = this.providers.get(socialProvider);
    if (!provider) {
      return throwError(() => 'Unknown provider');
    }
    return provider.state$;
  }
}
