import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {SocialAuthDirective} from './directives';
import {SocialAuthProviderConfig, SocialModuleAuthConfig} from './models';
import {SocialAuthService, SocialUserService} from './services';

@NgModule({
  declarations: [
    SocialAuthDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SocialAuthDirective,
  ],
  providers: [
    SocialAuthService,
  ],
})
export class SocialAuthModule {

  static forRoot<T extends SocialUserService>(
    config: SocialAuthProviderConfig[],
    service: unknown,
  ): ModuleWithProviders<SocialAuthModule> {
    return {
      ngModule: SocialAuthModule,
      providers: [
        {
          provide: SocialModuleAuthConfig,
          useValue: config,
        },
        {
          provide: SocialUserService,
          useExisting: service,
        },
      ],
    };
  }

  constructor() {
  }
}
