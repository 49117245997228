import {InjectionToken} from '@angular/core';

import {LoginProvider} from './login-provider';
import {SocialAuthProvider} from './social-provider';

export interface SocialAuthProviderConfig {
  readonly type: SocialAuthProvider;
  readonly provider: LoginProvider;
}

export const SocialModuleAuthConfig = new InjectionToken<SocialAuthProviderConfig[]>(
  'Injection token for configuration of SocialAuthConfig',
  {
    factory: () => [],
  },
);
