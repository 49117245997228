import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export interface SocialUserServiceI {
  socialAuth<T, W>(payload: T): Observable<W>;
}

/**
 * Service that injects inside SocialAuthService and should be replaced by service which implements socialAuth method
 */
@Injectable()
export class SocialUserService implements SocialUserServiceI {

  constructor() {
  }

  socialAuth<T, W>(payload: T): Observable<W> {
    throw new Error('Method not implemented');
  }
}
